import { CognifitSdk } from '@cognifit/launcher-js-sdk';
import { CognifitSdkConfig } from '@cognifit/launcher-js-sdk/lib/lib/cognifit.sdk.config';
import { Fancybox } from '@fancyapps/ui';
import Chart from 'chart.js/auto';
// import Cookies from 'js-cookie';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

jQuery(function ($) {
  $(document).ready(function () {
    /**
     * Add fancybox to images
     */
    $('.gallery-item')
      .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
      .attr('rel', 'gallery')
      .attr('data-fancybox', 'gallery');

    Fancybox.bind('[data-fancybox]');

    //variables for Cognift api
    let container = $('#cogniFitContent');
    let containerInitial = $('#cogniFitContentInitial');

    let currentType, currentSessionKey, currentToken, currentID;
    $('#cognifit-initital-form').on('submit', function (e) {
      e.preventDefault();
      currentType = containerInitial.attr('data-type');
      currentSessionKey = 'ASSESSMENT_AmPOr';
      grantAccessToken(true);
    });
    // Tracker tab functionality
    $('.js-tracker-link').on('click', function (e) {
      e.preventDefault();
      window.location.hash = $(this).children('a').attr('href');
      window.location.href = window.location;
      window.location.reload();
    })
    if (window.location.hash === '#tracker-tab') {
      $('#tracker-tab').addClass('linked');
    }
    //Scroll to form
    $('.activities-item-link').on('click', function (e) {
      e.preventDefault();
      $('.cognifit-info .tabs-title a').attr('aria-selected', 'false');
      $('.cognifit-info .tabs-panel').attr('aria-hidden', 'true');
      $('.cognifit-info .tabs-title, .cognifit-info .tabs-panel').removeClass(
        'is-active'
      );
      container.attr('data-type', $(this).attr('data-type'));
      container.attr('data-session-key', $(this).attr('data-key'));
      currentType = container.attr('data-type');
      currentSessionKey = container.attr('data-session-key');
      if ($(this).attr('data-type') === 'ASSESSMENT') {
        container.attr('data-id', $(this).attr('data-id'));
      }
      grantAccessToken();
      $('html').animate(
        {
          scrollTop:
            $('.js-cognifit__bottom').offset().top -
            $('.header__menu').height() -
            100,
        },
        800 //speed
      );
    });

    //Grant user cognify access token
    function grantAccessToken(initial = false) {
      $.ajax({
        url: ajax_object.ajax_url,
        data: {
          action: 'cognify_access_token',
          nonce: ajax_object.nonce,
          token: ajax_object.user_token,
        },
        type: 'post',
        dataType: 'json',
        beforeSend: function () {
          if (initial === false) {
            $('.cognifit-form-message').html('<h2>' + ajax_object.loading_activity_text + '</h2>');
          }
        },
        success: function (data) {
          if (initial === true) {
            containerInitial.attr('data-token', data);
            console.log(data);
            currentToken = containerInitial.attr('data-token');
            console.log(currentToken);
            loadCognify(true);
          } else {
            container.attr('data-token', data);
            console.log(data);
            currentToken = container.attr('data-token');
            console.log(currentToken);
            loadCognify();
          }
        },
        error: function () {},
        complete: function () {},
      });
    }

    //Grant user cognify access token
    function updateUserData() {
      $.ajax({
        url: ajax_object.ajax_url,
        data: {
          action: 'update_user_cognifit_data',
          nonce: ajax_object.nonce,
          token: ajax_object.user_token,
          id: container.attr('data-id') ? container.attr('data-id') : '',
          currentURL: window.location.href,
        },
        type: 'post',
        dataType: 'json',
        beforeSend: function () {
        },
        success: function () {
          // container.attr('data-token', data);
        },
        error: function () {},
        complete: function () {},
      });
    }

    //Initialize and load cognify iframe
    function loadCognify(initial = false) {
      let containerId = 'cogniFitContent';
      if (initial === true) {
        containerId = 'cogniFitContentInitial';
      }
      const cognifitSdkConfig = new CognifitSdkConfig(
        containerId,
        ajax_object.client_id,
        currentToken,
        {
          sandbox: false,
          appType: 'web', // 'web' or 'app'.
          theme: 'light', // 'light' or 'dark'.
          showResults: true,
          // customCss: '',            // Url to custom css file.
          screensNotToShow: [], // List of screens not to show after the session.
          scale: 800, // Default 800. Maximum value used to display values.
          listenEvents: true, // Default false. If true, events will be triggered during session life.
        }
      );
      const cogniVar = new CognifitSdk();
      cogniVar
        .init(cognifitSdkConfig)
        .then((response) => {
          if (initial === true) {
            containerInitial.show();
            cognifyStart(cogniVar, true);
          } else {
            $('.cognifit-form-message').html('');
            container.show();
            cognifyStart(cogniVar);
          }
        })
        .catch((error) => {
          $('.cognifit-form-message').html('');
          console.log('init error:' + error);
          console.log(cogniVar.cognifitSdkError.getError());
          console.log(cogniVar.cognifitSdkError.getMessage());
          console.log(cogniVar.cognifitSdkError);
        });
      // console.log(cogniVar.cognifitSdkError.getError());
      // console.log(cogniVar.cognifitSdkError.getMessage());
    }

    //Start cognify activity
    function cognifyStart(cogniVar, initial = false) {
      cogniVar.start(currentType, currentSessionKey).subscribe({
        next: (cognifitSdkResponse) => {
          if (cognifitSdkResponse.isSessionCompleted()) {
            cognifitSdkResponse.typeValue;
            cognifitSdkResponse.keyValue;
            if (cognifitSdkResponse.typeValue === 'ASSESSMENT' || cognifitSdkResponse.typeValue === 'TRAINING') {
              console.log('before update user function');
              updateUserData();
              console.log('after update user function');
            }
            if (cognifitSdkResponse.typeValue === 'ASSESSMENT') {
              $('.cognifit-form-message').html(
                ajax_object.assessment_completion_text
              );
            } else if (cognifitSdkResponse.typeValue === 'TRAINING') {
              $('.cognifit-form-message').html(
                ajax_object.training_completion_text
              );
            }
          }
          if (cognifitSdkResponse.isSessionAborted()) {
            console.log('You aborted the activity');
            if (initial === true) {
              containerInitial.hide();
            } else {
              container.hide();
            }
            $('.cognifit-form-message').html(
              '<h2>' + ajax_object.aborted_activity_text + '</h2>'
            );
          }
          if (cognifitSdkResponse.isErrorLogin()) {
            console.log('error login');
            console.log(cogniVar.cognifitSdkError.getMessage());
            if (initial === true) {
              containerInitial.hide();
            } else {
              container.hide();
            }
            $('.cognifit-form-message').html(
              '<h2>There was an error during login</h2>'
            );
          }
          if (cognifitSdkResponse.isEvent()) {
            const eventPayloadValues =
              cognifitSdkResponse.eventPayload.getValues();
            console.log('is event');
            console.log(cognifitSdkResponse.eventPayload);
          }
        },
        complete: () => {
          console.log('complete');
          if (initial === true) {
            containerInitial.hide();
          } else {
            container.hide();
          }
        },
        error: (reason) => {
          console.log('error reason');
          console.log(reason);
          console.log(cogniVar.cognifitSdkError.getMessage());
        },
      });
    }

    // Chart
    if (
      $('#chart-history').attr('data-scores') != '' &&
      $('#chart-history').length
    ) {
      let chartData = JSON.parse($('#chart-history').attr('data-scores'));
      let chartDataLabels = [];
      let chartDataSet = [];
      $.each(chartData, function (key, val) {
        chartDataLabels.push(key);
        chartDataSet.push(val);
      });
      chartDataLabels.unshift(' ');
      chartDataLabels.push('GOAL');
      chartDataLabels.push(' ');
      let fakeStartDot = chartDataSet[0] / 2;
      let goalDot =
        chartDataSet.slice(-1) * 1.1 < 800
          ? Math.round(chartDataSet.slice(-1) * 1.1)
          : '800';
      let fakeEndDot =
        chartDataSet.slice(-1) * 1.4 < 800
          ? Math.round(chartDataSet.slice(-1) * 1.4)
          : '800';
      chartDataSet.unshift(fakeStartDot);
      chartDataSet.push(goalDot);
      chartDataSet.push(fakeEndDot);
      let ctx = document.getElementById('chart-history').getContext('2d');
      let chart = new Chart(ctx, {
        // The type of chart we want to create
        type: 'line', // also try bar or other graph types
        // The data for our dataset
        data: {
          labels: chartDataLabels,
          // Information about the dataset
          datasets: [
            {
              label: 'User data',
              backgroundColor: '#f47b20',
              borderColor: '#4b216a',
              fill: 'start',
              data: chartDataSet,
              pointRadius: 0,
              pointHoverRadius: 0,
            },
          ],
        },

        // Configuration options
        options: {
          tension: 0.4,
          layout: {
            padding: {
              left: -8,
              bottom: -10,
              top: 50,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: '',
            },
            tooltip: {
              enabled: false,
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: function (val, context) {
                let goal =
                  context.dataset.data[context.dataset.data.length - 2];
                if (val == goal) {
                  return (
                    context.chart.data.labels[context.dataIndex] + '\n' + val
                  );
                }
              },
              display: function (context) {
                let index = context.dataIndex;
                let value = context.dataset.data[index];
                let firstElem = context.dataset.data[0];
                let lastElem = context.dataset.data.slice(-1);
                if (value == firstElem || value == lastElem[0]) {
                  return false;
                }
                return 'auto';
              },
              backgroundColor: function (context) {
                let index = context.dataIndex;
                let value = context.dataset.data[index];
                let goal =
                  context.dataset.data[context.dataset.data.length - 2];
                if (value == goal) {
                  return hexToRGB('#4b216a', 0.5);
                }
              },
              borderRadius: 5,
              padding: function (context) {
                let index = context.dataIndex;
                let value = context.dataset.data[index];
                let goal =
                  context.dataset.data[context.dataset.data.length - 2];
                if (value == goal) {
                  return 5;
                }
              },
              textAlign: 'center',
              offset: '10',
              color: function (context) {
                let index = context.dataIndex;
                let value = context.dataset.data[index];
                let goal =
                  context.dataset.data[context.dataset.data.length - 2];
                if (value == goal) {
                  return '#ffffff';
                }
                return '#f47b20';
              },
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              min: 1,
              max: 800,
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
              border: {
                display: false,
              },
            },
          },
        },
      });
    }
    if (
      $('#cognitivePerformanceChart').attr('data-info') != '' &&
      $('#cognitivePerformanceChart').length
    ) {
      var cognitivePerformanceChart = document
        .getElementById('cognitivePerformanceChart')
        .getContext('2d');
      let cognitivePerformanceChartData = JSON.parse(
        $('#cognitivePerformanceChart').attr('data-info')
      );
      let cognitivePerformanceChartDataLabels = [];
      let cognitivePerformanceChartDataSet = [];
      $.each(cognitivePerformanceChartData, function (key, val) {
        cognitivePerformanceChartDataLabels.push(this.key);
        cognitivePerformanceChartDataSet.push(this.score);
      });
      var cognitivePerformanceChartId = new Chart(cognitivePerformanceChart, {
        type: 'radar',
        data: {
          labels: cognitivePerformanceChartDataLabels,
          datasets: [
            {
              label: '',
              data: cognitivePerformanceChartDataSet,
              backgroundColor: hexToRGB('#000000', 0.2),
              pointBackgroundColor: [
                'yellow',
                'aqua',
                'pink',
                'lightgreen',
                'lightblue',
                'gold',
              ],
              borderColor: ['black'],
              borderWidth: 1,
              pointRadius: 6,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: '',
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            r: {
              min: 1,
              max: 800,
            },
          },
          elements: {
            line: {
              borderWidth: 3,
            },
          },
        },
      });
    }
    if (
      window.location.hash &&
      $('body').hasClass('page-template-template-digitalsolutions')
    ) {
      $('a[href="' + window.location.hash + '"]')
        .closest('li')
        .addClass('is-active');
      $(window.location.hash).addClass('is-active');
    }

    $(document).on('click', '.js-games-trial', function (e) {
      e.preventDefault();
      startGamesTrial();
    });

    //Start games trial
    function startGamesTrial() {
      $.ajax({
        url: ajax_object.ajax_url,
        data: {
          action: 'start_games_trial',
          nonce: ajax_object.nonce,
        },
        type: 'post',
        dataType: 'json',
        beforeSend: function () {},
        success: function (response) {
          $(document).find('.js-games-trial-response').text(response);
          setTimeout(function () {
            window.location.hash = '#games-tab';
            location.reload();
          }, 2000);
        },
        error: function () {},
        complete: function () {},
      });
    }
  });

  function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }
});
